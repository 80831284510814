import React from 'react'
import App from 'App'
import { COLOR_CONSTANTS } from 'theme'
import SEO from 'components/SEO'
import { Box, Flex } from 'components/Layout'
import { H1, Text } from 'components/Typography'
import Footer from 'components/Footer'
import styled from 'styled-components'

const Table = styled.table`
  text-align: left;
  border: 1px solid #cccccc;
  width: 100%;
`

const TD = styled.td`
  border: 1px solid #cccccc;
  padding: 10px;
  font-size: 14px;
`

const TH = styled(TD)`
  font-weight: bold;
`

const items = [
  {
    name: 'AWS (Amazon Web Services), Inc.',
    case: 'Hosting provider',
    location: 'United States',
  },
  {
    name: 'Algolia',
    case: 'Blog article search',
    location: 'United States',
  },
  {
    name: 'Firebase (Google LLC)',
    case: 'Mobile crash analytics',
    location: 'United States',
  },
  {
    name: 'Zendesk, Inc.',
    case: 'Support ticketing system',
    location: 'United States',
  },
  {
    name: 'Twilio Sendgrid',
    case: 'Email Service Provider',
    location: 'United States',
  },
  {
    name: 'OpenAI',
    case: 'Service provider for AI-enabled functionality',
    location: 'United States',
  },
  {
    name: 'Calendly',
    case: 'Online appointment scheduling software',
    location: 'United States',
  },
  {
    name: 'Intuit Inc (Quickbooks Online)',
    case: 'Invoicing and financial management',
    location: 'United States',
  },
  {
    name: 'Stripe',
    case: 'Payment Gateway (if user using credit card payment)',
    location: 'United States',
  },
  {
    name: 'Zoom',
    case: 'Chat communications tool',
    location: 'United States',
  },
  {
    name: 'Google Analytics',
    case: 'Website analytics',
    location: 'United States',
  },
  {
    name: 'MongoDB',
    case: 'Data hosting / Product database',
    location: 'United States',
  },
].sort((a, b) => (a.name < b.name ? -1 : 1))

const GDPR = () => {
  return (
    <App>
      <SEO
        title="Vista Social Subprocessors | Vista Social"
        description="List of Vista Social subprocessors"
        path="/subprocessors/"
      />
      <Flex mt="xl" px="m" alignItems="center" flexDirection="column" textAlign="center">
        <H1 color={COLOR_CONSTANTS.DENIM} fontSize="4xl" fontWeight="bold">
          Vista Social Subprocessors
        </H1>

        <Text mt="m" fontSize="xs" color="secondaryText">
          Effective September 10th, 2023
        </Text>

        <Box mx="auto" maxWidth="780px" textAlign="left">
          <Text mt="m" mb="m" fontSize="s" color="secondaryText" width="300px">
            As a data processor under the GDPR, Vista Social makes use of the sub-processors listed below to support
            Vista Social in its performance of services for its customers. Use of these sub-processors may be varied by
            the contract terms in place between Vista Social and a customer.
          </Text>
          <Table className="post">
            <thead>
              <tr>
                <TH>Name of company</TH>
                <TH>Use case</TH>
                <TH>Location</TH>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr>
                  <TD>{item.name}</TD>
                  <TD>{item.case}</TD>
                  <TD>{item.location}</TD>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
      </Flex>

      <Footer />
    </App>
  )
}

export default GDPR
